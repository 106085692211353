import $ from "jquery";
import "what-input";

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
// require("./lib/jquery.migrate");
require("foundation-sites");
require("waypoints/lib/jquery.waypoints");
require("flatpickr/dist/flatpickr");
require("./lib/jquery.magnific-popup");
var Swiper = require("./lib/swiper");
// var toastr = require("./lib/toastr");
// require("./lib/jquery.sticky-kit");

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

$(document)
  .foundation()
  .ready(function () {
    let mouseOut = [];
    $(".sub-nav").on("mouseover", function() {      
      clearTimeout(mouseOut[$(this).data("sub")]);
      $(this).find(".sub-nav-container").fadeIn(200);
    });
    $(".sub-nav").on("mouseout", function() {   
      let that = this;   
      mouseOut[$(this).data("sub")] = setTimeout(function() {
        $(that).find(".sub-nav-container").fadeOut(200);
      }, 400);
    });

    $(".popup").magnificPopup({
      type: "image",
      closeOnContentClick: false,
      mainClass: "mfp-img-mobile",
      image: {
        verticalFit: true,
      },
    });
    $("input.date").flatpickr({
      dateFormat: "j F Y",
      minDate: "today",
      maxDate: new Date().fp_incr(365),
    });
    $("input.datetime").flatpickr({
      dateFormat: "j F Y h:i K",
      enableTime: true,
      minTime: "07:00",
      maxTime: "16:00",
      minDate: "today",
      maxDate: new Date().fp_incr(365),
    });
    $(window).scroll(function (event) {
      scrollCheck();
      checkOffset();
    });

    $(window).resize(function () {
      Waypoint.refreshAll();
    });

    scrollCheck();
    checkOffset();

    if ($(".hero.swiper-container").length) {
      var heroSlider = new Swiper(".hero", {
        autoplay: {
          delay: 5000,
        },
        speed: 600,
        effect: "fade",
        loop: true,
      });
    }

    if ($(".service-slider").length) {
      var serviceSlider = new Swiper(".service-slider", {
        autoplay: {
          delay: 3500,
        },
        speed: 300,
        effect: "fade",
        loop: true,
      });
    }

    function scrollCheck() {
      if ($(window).scrollTop() > 80) {
        $("#scroll-top").fadeIn();
      } else {
        $("#scroll-top").fadeOut();
      }

      if ($(window).scrollTop() > 200) {
        $(".header").addClass("scrolled");
      } else {
        $(".header").removeClass("scrolled");
      }

      if ($(window).scrollTop() > 5) {
        $(".header").addClass("shadow");
      } else {
        $(".header").removeClass("shadow");
      }
    }

    $("#scroll-top").click(function () {
      $("body,html").animate(
        {
          scrollTop: 0,
        },
        1000
      );
    });

    $(".target-scroll").click(function (e) {
      e.preventDefault();

      var target = $(this).attr("href");
      var headerHeight = 69;

      if ($(target).length) {
        $("body,html").animate(
          {
            scrollTop: $(target).offset().top - headerHeight,
          },
          1000
        );
      }
    });

    function checkOffset() {
      if (
        $("#scroll-top").offset().top + $("#scroll-top").height() >=
        $(".footer").offset().top - 30
      ) {
        $("#scroll-top").css("position", "absolute");
      }
      if (
        $(document).scrollTop() + window.innerHeight <
        $(".footer").offset().top
      ) {
        $("#scroll-top").css("position", "fixed"); // restore when you scroll up
      }
    }

    $(".animate-up").waypoint(
      function (direction) {
        if (!$(this.element).hasClass("animate")) {
          $(this.element).addClass("animate");
        }
      },
      { offset: "90%" }
    );

    $("#hamburger").click(function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(".navigation, .main, .header").removeClass("active");
        $("html, body").removeClass("menu-open");
      } else {
        $(this).addClass("active");
        $(".navigation, .main, .header").addClass("active");
        $("html, body").addClass("menu-open");
      }
    });

    var cursor = "";
    var instagramBtn = $(".instagram-container .btn-g");

    if ($(".instagram-photo-container").length) {
      var instagramContainer = $(".instagram-photo-container");
      $.ajax({
        type: "GET",
        url: instagramContainer.data("url"),
        success: function (res) {
          var result = JSON.parse(res);
          cursor = result.cursor;
          if (cursor) {
            instagramBtn.fadeIn();
          } else {
            instagramBtn.fadeOut();
          }
          for (let i = 0; i < result.media.length; i++) {
            instagramContainer.append(
              '<div><img class="instagram-photo" src="' +
                result.media[i] +
                '" alt="instagram photo" /></div>'
            );
          }
        },
        error: function (res) {
          console.log("Error");
        },
      });
    }

    instagramBtn.click(function () {
      if (cursor) {
        $.ajax({
          type: "GET",
          url: instagramContainer.data("url"),
          data: { endCursor: cursor },
          success: function (res) {
            var result = JSON.parse(res);
            cursor = result.cursor;
            if (cursor) {
              instagramBtn.fadeIn();
            } else {
              instagramBtn.fadeOut();
            }
            for (let i = 0; i < result.media.length; i++) {
              instagramContainer.append(
                '<div><img class="instagram-photo" src="' +
                  result.media[i] +
                  '" alt="instagram photo" /></div>'
              );
            }
          },
          error: function (res) {
            console.log("Error");
          },
        });
      } else {
        instagramBtn.fadeOut();
      }
    });

    // file handler
    $("input[type=file]").change(function () {
      var textElement = $(this).closest("label").find(".text");
      var files = $(this)[0].files;
      if (files.length) {
        textElement.html(textElement.data("default"));
      }

      for (var i = 0; i < files.length; i++) {
        textElement.html(files[i].name);
      }
    });

    // colour form
    $("#colour-form").submit(function (e) {
      e.preventDefault();      
      
      $(this).find(".response-container p").text("");

      $(this)
        .find("button")
        // .text("Sending")
        .prop("disabled", true)
        .addClass("disabled");

      var that = this;
      $.ajax({
        type: "POST",
        url: $(this).attr("action"),
        data: new FormData(this),
        processData: false,
        contentType: false,
        success: function (res) {
          if (res == 1) {
            $(that)
              .find(".response-container p")
              .text(
                "Thank you, due to a high amount of enquiries received a day, we do ask that you please allow 3 - 5 business days for a response."
              );
            $(that).find("input, select, textarea").val("");
          } else {
            $(that)
              .find(".response-container p")
              .text("Something went wrong, please try again later!");
          }

          $(that)
            .find("button")
            // .text("Send")
            .prop("disabled", false)
            .removeClass("disabled");
        },
        error: function (res) {
          $(that)
            .find(".response-container p")
            .text("Something went wrong, please try again later!");
          $(that)
            .find("button")
            // .text("Send")
            .prop("disabled", false)
            .removeClass("disabled");
        },
      });
    });

    // other form
    $("#event-form, #style-form, #extension-form, #bridal-book-form").submit(function (e) {
      e.preventDefault();      
      
      $(this).find(".response-container p").text("");
      var isBridalBookForm = $(this).attr("id") == "bridal-book-form";

      $(this)
        .find("button")
        // .text("Sending")
        .prop("disabled", true)
        .addClass("disabled");

      var that = this;
      $.ajax({
        type: "POST",
        url: $(this).attr("action"),
        data: new FormData(this),
        processData: false,
        contentType: false,
        success: function (res) {
          if (res == 1) {
            $(that)
              .find(".response-container p")
              .text(
                "Thank you, due to a high amount of enquiries received a day, we do ask that you please allow 3 - 5 business days for a response."
              );
            $(that).find("input, select, textarea").val("");
            if (isBridalBookForm) {
              $("#postcode").val("").prop("required", false).hide();
            }
          } else {
            $(that)
              .find(".response-container p")
              .text("Something went wrong, please try again later!");
          }

          $(that)
            .find("button")
            // .text("Send")
            .prop("disabled", false)
            .removeClass("disabled");
        },
        error: function (res) {
          $(that)
            .find(".response-container p")
            .text("Something went wrong, please try again later!");
          $(that)
            .find("button")
            // .text("Send")
            .prop("disabled", false)
            .removeClass("disabled");
        },
      });
    });

    $("#location").change(function () {
      if ($(this).val() == "On Location / Post code") {
        $("#postcode").val("").prop("required", true).show().focus();
      } else {
        $("#postcode").val("").prop("required", false).hide();
      }
    });

    $(".policy").magnificPopup({
      type: "ajax",
    });

    $(function () {
      var boxes = $("[data-scroll-speed]"),
        $window = $(window);
      $window.on("scroll", function () {
        var scrollTop = $window.scrollTop();
        boxes.each(function () {
          var $this = $(this),
            scrollspeed = parseInt($this.data("scroll-speed")),
            val = -scrollTop / scrollspeed;
          val = val * -1;
          $this.css("transform", "translateY(" + val + "px)");
        });
      });
    });
  });
